import { connect } from 'react-redux';
import Login from '../components/Login';
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    apiStatus: state.service.apiStatus,
    user: state.service.user,
  };
}


const mapDispatchToProps = (dispatch) => ({
  login: (username, password, callback) => {
    dispatch(api.signIn(username, password)).then(callback);
  },
})


export default connect(mapStateToProps, mapDispatchToProps)(Login)
