import { connect } from 'react-redux';
import Sell from '../components/Sell';
import * as api from '../store/api'

const mapStateToProps = (state) => {
    return {
        apiStatus: state.service.apiStatus,
        user: state.service.user,
    };
}


const mapDispatchToProps = (dispatch) => ({
    checkUser: (callback) => {
        dispatch(api.checkUserSession()).then(callback)
    },
    purchase: (data, callback) => {
        dispatch(api.postData("/selltickets", "SELL_TICKETS", data, true)).then(callback);
    },
    checkAddress: (addressStr, callback) => {
        dispatch(api.checkAddress(addressStr)).then(callback)
    },
    addressLookup: (addressStr, callback) => {
        dispatch(api.addressLookup(addressStr)).then(callback)
    },
    addressData: (addressId, callback) => {
        dispatch(api.addressData(addressId)).then(callback)
    },
})


export default connect(mapStateToProps, mapDispatchToProps)(Sell)
