import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'

import _ from 'lodash'
import update from 'immutability-helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Alert, Form, Modal, Dropdown, Table } from 'react-bootstrap'
import Autosuggest from 'react-autosuggest';
import StringHelpers from '../helpers/StringHelpers'
import 'babel-polyfill';
import moment from 'moment';

import '../styles/global.css'

class PurchaseSummary extends Component {

    state = {
        editMode: false,
        newDetails: this.props.details,
        details: this.props.details
    }

    render() {

        let self = this

        let cancelBtn = this.state.editMode ?
            <Button variant="secondary" onClick={() => this.setState({ newDetails:self.state.details, editMode: false })} >Cancel</Button>
            : <Button variant="secondary" onClick={self.props.cancel} >Back</Button>

        let actionBtn = this.state.editMode ?
            <Button variant="success" onClick={() => { this.setState({ details: self.state.newDetails, editMode: false }) }}>Save</Button>
            : <Button variant="warning" onClick={() => this.setState({ editMode: true })}>Edit</Button>

        let purchaseBtn = this.state.editMode ?
            null
            : <Button variant="success" onClick={() => self.props.confirmPurchase(self.state.details)}>Purchase</Button>

        let fields = []
        if (this.state.editMode) {
            fields.push(

                <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                First Name
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter first name"
                                value={self.state.newDetails.firstName}
                                onChange={(e) => {
                                    self.setState({
                                        newDetails: update(self.state.newDetails, { firstName: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Last Name
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter last name"
                                value={self.state.newDetails.lastName}
                                onChange={(e) => {
                                    self.setState({
                                        newDetails: update(self.state.newDetails, { lastName: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        {self.state.newDetails.noEmailSupplied ? null :
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Email
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter email"
                                value={self.state.newDetails.email}
                                onChange={(e) => {
                                    self.setState({
                                        newDetails: update(self.state.newDetails, { email: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group> }

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Mobile Number
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter mobile number"
                                value={self.state.newDetails.phone}
                                onChange={(e) => {
                                    self.setState({
                                        newDetails: update(self.state.newDetails, { phone: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Address
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter address"
                                value={self.state.newDetails.address}
                                onChange={(e) => {
                                    self.setState({
                                        newDetails: update(self.state.newDetails, { address: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Suburb
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter suburb"
                                value={self.state.newDetails.suburb}
                                onChange={(e) => {
                                    self.setState({
                                        newDetails: update(self.state.newDetails, { suburb: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Postcode
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter postcode"
                                value={self.state.newDetails.postcode}
                                onChange={(e) => {
                                    self.setState({
                                        newDetails: update(self.state.newDetails, { postcode: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>
                        
                        
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                State
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter state"
                                value={self.state.newDetails.state}
                                onChange={(e) => {
                                    self.setState({
                                        newDetails: update(self.state.newDetails, { state: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Check
                            type="checkbox"
                            label="Not for publication"
                            checked={self.state.newDetails.nfp}
                            onChange={(e) => {
                                self.setState({
                                    newDetails: update(self.state.newDetails, { nfp: { $set: e.target.checked } })
                                })
                            }}
                        />

                        <Form.Check
                            type="checkbox"
                            label="Printed ticket required"
                            checked={self.state.newDetails.printedTicketRequired}
                            disabled={self.state.newDetails.noEmailSupplied}
                            onChange={(e) => {
                                self.setState({
                                    newDetails: update(self.state.newDetails, { printedTicketRequired: { $set: e.target.checked } })
                                })
                            }}
                        /> 

                        <Form.Check
                            type="checkbox"
                            label="Out out of SMS"
                            checked={self.state.newDetails.optOutOfSMS}
                            onChange={(e) => {
                                self.setState({
                                    newDetails: update(self.state.newDetails, { optOutOfSMS: { $set: e.target.checked } })
                                })
                            }}
                        />

                        <Form.Check
                            type="checkbox"
                            label="Out out of DM"
                            checked={self.state.newDetails.optOutOfDM}
                            onChange={(e) => {
                                self.setState({
                                    newDetails: update(self.state.newDetails, { optOutOfDM: { $set: e.target.checked } })
                                })
                            }}
                        />

                        <Form.Check
                            type="checkbox"
                            label="VIP Subscription"
                            checked={self.state.newDetails.subscription}
                            onChange={(e) => {
                                self.setState({
                                    newDetails: update(self.state.newDetails, { subscription: { $set: e.target.checked } })
                                })
                            }}
                        />

                    </Form>

            )
        } else {
            fields.push(<Row className="paddingTop20 paddingBottom20">
                    <Col md={12}>
                        <h3 style={{ fontSize: 24 }}>Ticket Details &amp; Order Summary</h3>
                        <p>
                            <strong>Name</strong>: {this.state.newDetails.firstName} {this.state.newDetails.lastName}<br />
                            <strong>Email</strong>: {this.state.newDetails.noEmailSupplied ? "No Email Supplied" : this.state.newDetails.email}<br />
                            <strong>Address</strong>: {this.state.newDetails.address}, {this.state.newDetails.suburb} {this.state.newDetails.state} {this.state.newDetails.postcode}<br />
                            <strong>Number of tickets purchased</strong>: {this.state.newDetails.numberOfTickets}<br />
                            <strong>NFP</strong>: {this.state.newDetails.nfp ? "Yes" : "No"}<br />
                            <strong>VIP Subscription</strong>: {this.state.newDetails.subscription ? "Yes" : "No"}<br />
                            <strong>Opt out of SMS</strong>: {this.state.newDetails.optOutOfSMS ? "Yes" : "No"}<br />
                            <strong>Opt out of DM</strong>: {this.state.newDetails.optOutOfDM ? "Yes" : "No"}<br />
                            <strong>Printed Ticket Required</strong>: {this.state.newDetails.printedTicketRequired ? "Yes" : "No"}<br />
                            <strong>Total amount</strong>: ${Number(this.state.newDetails.numberOfTickets) * 100}<br />
                        </p>
                        
                    </Col>
                </Row>)
        }

        return (
            <Modal backdrop="static" show={true} keyboard={false} size="lg">
                <Modal.Header>
                    <Modal.Title>Edit Purchase</Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    {fields}
                </Modal.Body>

                <Modal.Footer>
                    {cancelBtn}
                    {actionBtn}
                    {purchaseBtn}

                </Modal.Footer>
            </Modal>
        )

    }

}

export default withRouter(PurchaseSummary)