const initialState = {
    user: null,

    events: null,
    event: null,
    eventMetrics: null,
    ctaWinner: null,
    earlyBird1Winner: null,
    earlyBird2Winner: null,
    majorPrizeWinner: null,
    minorPrizeWinners: null,
    minorPrizeDrawInProgress: null,
    minorPrizeDrawComplete: null,

    purchaseComplete: false,
    purchaseError: ""
}

const service = (state = initialState, action) => {
    switch (action.type) {
        case 'API_LOADING':
        case 'API_FETCHING':
        case 'API_IDLE':
            return { ...state, apiStatus: action.type };
        case 'SIGN_IN':
        case 'SIGN_OUT':
        case 'ON_UPDATE_USER':
            return { ...state, user: action.data };
        case 'CLEAR_METRICS':
            return { ...state, eventMetrics: null, ctaWinner: null, earlyBird1Winner: null };
        case 'GET_EVENT_METRICS':
            return { ...state, eventMetrics: action.data };
        case 'GET_EVENTS':
            return { ...state, events: action.data };
        case 'SELECT_EVENT':
            return { ...state, event: action.data };
        case 'GET_CTA_WINNER':
            return { ...state, ctaWinner: action.data };
        case 'GET_EARLY_BIRD_1_WINNER':
            return { ...state, earlyBird1Winner: action.data };
        case 'GET_EARLY_BIRD_2_WINNER':
            return { ...state, earlyBird2Winner: action.data };
        case 'GET_MAJOR_PRIZE_WINNER':
            return { ...state, majorPrizeWinner: action.data };
        case 'GET_MINOR_PRIZE_WINNERS':
            return { ...state, minorPrizeWinners: action.data };
        case 'GET_MINOR_PRIZE_DRAW_PROGRESS':
            return { ...state, apiStatus:action.type ,minorPrizeDrawInProgress: action.data.inProgress, minorPrizeDrawComplete: action.data.complete };
        default:
            return state;
    }
}

export default service
