import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, ProgressBar, Form, DropdownButton, Dropdown } from 'react-bootstrap'

import '../styles/global.css'

class Prizes extends Component {

    state = {
        loading: true,
        busy: false
    }

    componentDidMount() {

        this._getPrizes = this._getPrizes.bind(this)
        this._drawWinner = this._drawWinner.bind(this)
        this._prepareDraw = this._prepareDraw.bind(this)

        if (!this.props.user) {
            this.props.checkUser((u) => {
                if (!this.props.user) {
                    console.log(this.props.user)
                    this.props.history.push("/login")
                } else {
                    this.setState({ loading: false, busy: true }, () => {
                        this._getPrizes()
                    })
                }
            })
        } else {
            this.setState({ loading: false, busy: true }, () => {
                this._getPrizes()
            })
        }
    }

    _getPrizes() {
        let self = this
        if (self.props.user.attributes["custom:role"] != "admin") {
            this.props.history.push("/")
            return
        }
        self.props.getPrizes((data) => {
            if (data.error) {
                this.props.history.push("/")
            } else {
                self.setState({
                    prizes: data.prizes,
                    busy: false
                })
            }

        })
    }

    _prepareDraw(prize) {
        let self = this

        self.setState({ busy: true }, () => {
            self.props.prepareDraw(prize, (res) => {
                self.setState({ busy: false }, () => {
                    if (window.confirm("You are about to draw the " + prize.name + " winner. The cut off date for eligible tickets is " + moment(res.maxDate).format("D MMMM YYYY, h:mma") + ". Of the " + res.totalTickets + " there are " + res.validTickets + " eligible tickets. Are you sure you want to draw a winner? This action cannot be undone.")) {
                        self._drawWinner(prize)
                    }
                })


            })
        })


    }

    _drawWinner(prize) {
        let self = this

        //if (window.confirm("Are you sure you want to draw a winner? This action cannot be undone.")) {
        self.setState({ busy: true }, () => {
            self.props.drawWinner(prize, (res) => {

                if (res && res.success) {
                    self._getPrizes()
                } else {
                    self.setState({ busy: false })
                    alert(res.message ? res.message : "Error drawing prize")
                }
            })
        })
        //}
    }

    logout = () => {
        this.setState({ busy: true }, () => {
            this.props.logout(() => {
                this.setState({ busy: false }, () => {
                    this.props.history.push("/login")
                })

            })
        })

    }



    render() {
        let self = this
        var logoutBtn = <Button disabled={this.state.busy}
            variant="info"
            onClick={this.logout}>
            {this.state.busy ? <FontAwesomeIcon spin icon={faCircleNotch} />
                : null}
            LOGOUT
        </Button>

        var prizes = <div className="loaderContainer">
            <div className="lds-dual-ring"></div>
        </div>

        if (self.state.prizes) {
            prizes = []
            _.forEach(self.state.prizes, (p) => {
                prizes.push(
                    <div style={{ marginTop: 20 }}>
                        <Button disabled={this.state.busy || p.status == "complete"} onClick={() => self._prepareDraw(p)}
                            variant="success">
                            Draw {p.name}
                        </Button>
                        <br />
                        {p.status == "complete" ? <p>
                            Winning Ticket - {p.winningTicket.ticketNumber}<br />
                            Purchase ID - {p.winningTicket.purchaseId}
                            {/* {p.winningTicket.firstName} {p.winningTicket.lastName}<br />
                            Phone: {p.winningTicket.phone}<br />
                            Email: {p.winningTicket.email} */}
                        </p> : null}
                    </div>
                )
            })
        }

        return (
            self.state.loading || self.state.busy ?
                <div className="loaderContainer">
                    <div className="lds-dual-ring"></div>
                </div>
                :
                <Container className="mainContainer">
                    <Row style={{ marginBottom: 20 }}>
                        <Col md={12}>
                            <Button disabled={this.state.busy} onClick={() => this.props.history.push("/")}
                                variant="info">
                                Back to Dashboard
                            </Button>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {prizes}
                        </Col>
                    </Row>


                </Container>
        )
    }
}

export default withRouter(Prizes)