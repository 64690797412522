import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'

import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Carousel, Form, DropdownButton, Dropdown } from 'react-bootstrap'

import '../styles/global.css'

class Dashboard extends Component {

    state = {
        loading: true,
        busy: false
    }

    componentDidMount() {
        if (!this.props.user) {
            this.props.checkUser((u) => {
                if (!this.props.user) {
                    console.log(this.props.user)
                    this.props.history.push("/login")
                } else {
                    this.setState({ loading: false })
                }
            })
        } else {
            this.setState({ loading: false })
        }
    }

    logout = () => {
        this.setState({ busy: true }, () => {
            this.props.logout(() => {
                this.setState({ busy: false }, () => {
                    this.props.history.push("/login")
                })

            })
        })

    }



    render() {
        let self = this
        var logoutBtn = <Button disabled={this.state.busy}
            variant="info"
            onClick={this.logout}>
            {this.state.busy ? <FontAwesomeIcon spin icon={faCircleNotch} />
                : null}
            LOGOUT
            </Button>



        return (
            this.state.loading ?
                <div className="loaderContainer">
                    <div className="lds-dual-ring"></div>
                </div>
                :
                <Container className="mainContainer">
                    {self.props.user && (self.props.user.attributes["custom:role"] == "admin" || self.props.user.attributes["custom:role"] == "seller") ?
                        <Row>
                            <Col md={12}>
                                <Button disabled={this.state.busy} onClick={() => this.props.history.push("/sell")}>
                                    Sell tickets
                        </Button>
                                <br />
                                <br />
                                {self.props.user.attributes["custom:sell_only"] == true ?
                                    null : <Button disabled={this.state.busy} onClick={() => this.props.history.push("/manage")}>
                                        Update/Resend a ticket
                    </Button>}
                            </Col>
                        </Row> : null}


                    <Row style={{ marginTop: 50 }}>
                        <Col md={12}>
                            {
                                self.props.user && self.props.user.attributes["custom:role"] == "admin" ?
                                    <Button disabled={this.state.busy} onClick={() => this.props.history.push("/prizes")}
                                        variant="success">
                                        Manage Prize Draws
                        </Button> : null}


                            <br />
                            <br />
                            {
                                self.props.user && (self.props.user.attributes["custom:role"] == "admin" || self.props.user.attributes["custom:role"] == "analytics") ?
                                    <Button disabled={this.state.busy} onClick={() => this.props.history.push("/analytics")}
                                        variant="success">
                                        Analytics
                        </Button> : null
                            }
                        </Col>
                    </Row>


                    <hr />
                    {self.props.user ? <div>You are logged in with {self.props.user.attributes["custom:role"]} privilege. ({self.props.user.attributes.email})</div> : null}
                    {logoutBtn}
                </Container>
        )
    }
}

export default withRouter(Dashboard)