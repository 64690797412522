import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'

import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Carousel, Form, DropdownButton, Dropdown } from 'react-bootstrap'

import '../styles/global.css'

class Login extends Component {

    state = {
        email: "",
        password: "",
        busy: false
    }

    componentDidMount() {
        if (this.props.user) {
            this.props.history.push("/")
        }
    }

    login = () => {
        this.setState({ busy: true }, () => {
                this.props.login(this.state.email, this.state.password, () => {
                    this.setState({ busy: false }, () => {
                        if(this.props.user) {
                        this.props.history.push("/")
                    }
                })

            })
        })

    }


    render() {

        var submitBtn = <Button disabled={this.state.busy} onClick={this.login}>
            {this.state.busy ? <FontAwesomeIcon spin icon={faCircleNotch} />
                : null}
            SUBMIT
            </Button>

        return (
            <Container className="mainContainer">

                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email"
                            disabled={this.state.busy}
                            value={this.state.email}
                            onChange={(e) => {
                                this.setState({ email: e.target.value });
                            }} />

                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password"
                            disabled={this.state.busy}
                            value={this.state.password}
                            onChange={(e) => {
                                this.setState({ password: e.target.value });
                            }}
                        />
                    </Form.Group>

                    {submitBtn}
                </Form>

            </Container>
        )
    }
}

export default withRouter(Login)