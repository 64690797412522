import { connect } from 'react-redux';
import Prizes from '../components/Prizes';
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    apiStatus: state.service.apiStatus,
    user: state.service.user,
    events: state.service.events,

  };
}


const mapDispatchToProps = (dispatch) => ({
  logout: (callback) => {
    dispatch(api.signOut()).then(callback)
  },
  checkUser: (callback) => {
    dispatch(api.checkUserSession()).then(callback)
  },
  getPrizes: (callback) => {
    dispatch(api.getData("/getprizes", "GET_PRIZES", "", true)).then(callback)
  },
  prepareDraw: (prize, callback) => {
    dispatch(api.postData("/preparedraw", "PREPARE_DRAW", prize, true)).then(callback)
  },
  drawWinner: (prize, callback) => {
    dispatch(api.postData("/drawwinner", "DRAW_WINNER", prize, true)).then(callback)
  }

})


export default connect(mapStateToProps, mapDispatchToProps)(Prizes)
