import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { loadingBarMiddleware } from 'react-redux-loading-bar'

import rootReducer from './store/reducers';


import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom'

import Dashboard from './containers/DashboardContainer';
import Login from './containers/LoginContainer';
import Sell from './containers/SellContainer';
import Analytics from './containers/AnalyticsContainer';
import ManageSales from './containers/ManageSalesContainer';
import Tickets from './containers/TicketsContainer';
import Prizes from './containers/PrizesContainer';

import '../src/styles/global.css'

const loggerMiddleware = createLogger()

const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        loadingBarMiddleware())
)


ReactDOM.render(

    <Provider store={store}>

        <Router>
            <div>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/sell" component={Sell} />
                <Route exact path="/manage" component={ManageSales} />
                <Route exact path="/analytics" component={Analytics} />
                <Route exact path="/tickets" component={Tickets} />
                <Route exact path="/prizes" component={Prizes} />
            </div>
        </Router>
    </Provider>
    ,

    document.getElementById('root')
);
