import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import _, { pad } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Button, Carousel, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { mhl_logo, range_rover, range_rover_bg, details_frame, confetti } from "../assets/Assets";

import "../styles/global.css";
import StringHelpers from "../helpers/StringHelpers";

class Tickets extends Component {
  state = {
    loading: true,
    busy: false,
  };

  componentDidMount() {
    let self = this;
    const encodedPurchase = self.props.location.search.split("?")[1];
    const decodedPurchase = (encodedPurchase && atob(encodedPurchase)) || "{}";
    self.setState({
      loading: false,
      purchase: JSON.parse(decodedPurchase),
    });
  }

  render() {
    let self = this;
    let tickets;
    if (self.state.purchase) {
      const p = self.state.purchase;
      let ticketStr = "";
      for (let i = p.ticketIndex; i < p.ticketIndex + p.ticketCount; i++) {
        if (ticketStr != "") ticketStr += ", ";
        ticketStr += "CDFC001_" + StringHelpers.pad(i, 5);
        console.log(i);
      }
      tickets = (
        <div id="pdfready" style={{ display: "flex" }}>
          <div>
            <div>
              {p.firstName} {p.lastName}
            </div>
            <div>
              {p.address}
              <br />
              {p.suburb} {p.postcode}, {p.state}
            </div>
          </div>
          <div className="ticker-number" style={{ marginLeft: "100px" }}>
            Ticket Number/s:
            <br />
            {ticketStr}
          </div>
        </div>
      );
    }

    return self.state.loading ? (
      <div></div>
    ) : (
      <Container
        style={{
          backgroundColor: "#ece7eb",
        }}
      >
        <Row className="printedTicketInner" style={{ justifyContent: "center" }}>
          <Container
            className="mainContainer printedTicket"
            style={{
              backgroundColor: "#ece7eb",
              padding: "0px",
            }}
          >
            <img style={{ width: "100%" }} src="/images/CDFCLotteryTicket.jpg" />
            <Row style={{ justifyContent: "center" }}>
              <div
                style={{
                  width: "707px",
                  backgroundColor: "white",
                  height: "170px",
                  verticalAlign: "middle",
                  marginRight: "37px",
                  position: "absolute",
                  padding: "60px",
                  paddingLeft: "90px",
                  paddingTop: "43px",
                }}
              >
                <div className="label-container">{tickets}</div>
              </div>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                height: "355px",
              }}
            >
              <img src="images/officialTicket.webp" width="100%" alt=""></img>
            </Row>
          </Container>
          <Col md={12} className="page-divider"></Col>
          <Row style={{ width: "826px" }}>
            <Col xs={6} className="terms-text">
              <p>
                <strong>
                  CENTRAL DISTRICT FOOTBALL CLUB LOTTERY
                  <br />
                  Lottery Licence Number M14290
                </strong>
              </p>

              <p>
                <strong>Terms and Conditions</strong>
              </p>

              <p>
                <strong>
                  <u>1. Key Details</u>
                </strong>
              </p>

              <ul>
                <li>
                  The lottery is being conducted by CENTRAL DISTRICT FOOTBALL CLUB (ACN 610 395 296) in support of, and
                  in conjunction with NOVITA SERVICES (ACN 156 612 047), for the equal distribution of net proceeds
                  (50/50) between the two non-profit organisations.
                </li>
                <li>Ticket sales commence on 5th October 2022.</li>
                <li>
                  Ticket sales close at 11.59 pm (South Australia time) on 28th July 2023, unless sold out prior.
                </li>
                <li>Two prizes will be awarded (Early Bird Prize and First Prize).</li>
                <li>A maximum of 3,750 tickets will be sold.</li>
                <li>Tickets are $50 each.</li>
                <li>Total recommended retail value of all prizes is approximately $55,000</li>
              </ul>

              <p>
                <strong>
                  <u>2. Prizes</u>
                </strong>
              </p>

              <p>
                <strong>2.1 Early Bird Prize</strong>
                <br />
                The Early Bird Prize winner will win $2,000 in Cash. Only tickets purchased before 11:59pm on 9th of
                November, 2022 are eligible for the Early Bird Prize. The winner of the Early Bird Prize will remain
                eligible to win the First Prize.
                <br />
                <strong>2.2 First Prize</strong>
                <br />
                <strong>(a)</strong> The First Prize winner may choose between:
                <br />
                (i) $45,000 cash; OR
                <br />
                (ii) a Peter Page Hyundai Santa Fe Active petrol FWD valued at $52,990.
                <br />
                <strong>(b)</strong> Tickets purchased before 11:59pm on 28th July 2023 are eligible for a First Prize.
                <br />
                <strong>2.4 Other details</strong>
                <br />
                <strong>(a)</strong> All prizes are not transferable, or otherwise substitutable, by the prize winner.
                <br />
                <strong>(b)</strong> No component of a non-cash prize may be redeemed for cash.
                <br />
                <strong>(c)</strong> All prizes are subject to availability. If any prize or part of it becomes
                unavailable due to circumstances beyond Central District Football Club’s reasonable control, it reserves
                the right to provide a similar product of equivalent value as the original prize, subject to any
                applicable laws.
                <br />
                <strong>(d)</strong> In relation to the non-cash First Prize option:
                <br />
                (i) the recommended retail price includes cost of delivery to locations in metropolitan Adelaide, stamp
                duties, CTP and 12 months registration;
                <br />
                (ii) the actual vehicle may vary from the image, which is used for illustrative purposes only;
                <br />
                (iii) The Hyundai Santa Fe Active is expected to be available at the completion of the lottery, but may
                be subject to change;
                <br />
                (iv) the prize offered is for the Hyundai Santa Fe Active FWD. Any upgrades or extras are to be
                discussed directly with Peter Page Hyundai and will be at the cost of the winner.
              </p>

              <p>
                <strong>
                  <u>3. Draws</u>
                </strong>
              </p>

              <p>
                <strong>3.1 Time and location of draws</strong>
                <br />
                <strong>(a)</strong> The Early Bird Prize will be drawn from 10.00am on 10th November 2022.
                <br />
                <strong>(b)</strong> The First Prize will be drawn from 10.00am on 31st July 2023.
                <br />
                <strong>(c)</strong> All draws will occur at the offices of the Central District Football Club Inc,
                Goodman Road, Elizabeth, SA 5112.
                <br />
                <strong>3.2 Draw details</strong>
                <br />
                <strong>(a)</strong> All draws will be determined by lot (using random number generator software
                developed for the purposes of assisting the conduct of lotteries).
                <br />
                <strong>(b)</strong> All draws will occur:
                <br />
                (i) Central District Football Club and Novita
                <br />
                (ii) in the presence of a scrutineer who is not associated with the Central District Football Club and
                Novita Services or otherwise involved in the conduct of the lottery; and
                <br />
                (iii) in the presence of any ticket holders who wish to be present.
              </p>

              <p>
                <strong>
                  <u>4. Winner notification and collection of prizes</u>
                </strong>
              </p>

              <p className="p-last">
                <strong>4.1 Notification</strong>
                <br />
                <strong>(a)</strong> All winners will be contacted via a telephone call or email from the Central
                District Football Club and Novita Services.
                <br />
                <strong>(b)</strong> If a prize remains unclaimed for at least three-months after it is drawn, the
                Central District Football Club and Novita Services will make additional attempts to contact that winner
                to ensure they are aware they have won a prize.
                <br />
                <strong>4.2 Publication</strong>
                <br />
                <strong>(a)</strong> The prize winner’s name will be published:
                <br />
                (i) online at <a href="cdfc.com.au">cdfc.com.au</a> and <a href="novita.org.au">novita.org.au</a> no
                later than 24 hours after the prize is drawn
                <br />
                <strong>(b)</strong> Ticket purchasers may request to have their name excluded from publication by
                contacting cdfc.com.au or calling 08 8255 2555.
                <br />
                <strong>4.3 Claim</strong>
                <br />
                <strong>(a)</strong> It is the responsibility of the prize winner to claim their prize.
                <br />
                <strong>(b)</strong> Prizes will be awarded to the first person named on the official ticket. Prize-
                winners may be asked to provide 100 points of identification, as a condition of prize collection
                occurring.
                <br />
                <strong>(c)</strong> Each winner will have six-months from the final draw date to claim their prize.
              </p>
            </Col>
            <Col xs={6} className="terms-text">
              <p>
                <strong>
                  <u>5. Other matters</u>
                </strong>
              </p>
              <strong>5.1 Agreement</strong>
              <br />
              <strong>(a)</strong> This lottery is conducted in South Australia under Major lottery Licence No. #M14290
              issued by the Office of Business and Consumer Services.
              <br />
              <strong>(b)</strong> By entering the lottery, you represent that you have read, understood and agreed to
              these terms and conditions.
              <br />
              <p className="p-last">
                <strong>5.2 Eligibility for entry</strong>
                <br />
                <strong>(a)</strong> Entry is open to any individual who is:
                <br />
                (i) 18 years of age or older; and
                <br />
                (ii) not a member of the (A) Central District Football Club Board of Directors OR (B) Novita Services, (C) Split 50-50 Raffle Systems, and (D) JP Media who are directly involved in the conduct and promotion of the lottery.
                <br />
                <strong>(b)</strong> Ticketing Prizes will be awarded to the person named on the official ticket. Only
                one prize will be awarded to the group of individuals, where more than one person’s name appears on the
                ticket (by way of illustrative example only and for the avoidance of doubt: if more than one name
                appeared on the ticket that wins the Early Bird prize, a total of $2,000 only will be awarded to the
                first named person on the ticket).
                <br />
                <strong>(c)</strong> Multiple ticket purchases will have all numbers printed on one single sheet, being
                the official ticket.
                <br />
                <strong>(d)</strong> The Central District Football Club and Novita Services accepts no responsibility
                for any entries not received for any reason during the lottery period. No responsibility will be taken
                for lost, late or misdirected entries.
                <br />
                <strong>(e)</strong> The Central District Football Club and Novita Services are not responsible for
                unforeseen technical difficulties with the ordering process and do not guarantee the ordering process
                will always be available.
                <br />
                <strong>(f)</strong> All requests for refunds will be honoured if the method of payment has been
                transacted and the corresponding ticket has not been included in any prize draws.
                <br />
                <strong>5.3 Personal information</strong>
                <br />
                <strong>(a)</strong> Your personal information will be collected by Central District Football Club and
                Novita Services in connection with this lottery and:
                <br />
                (i) used to administer the lottery, enable the identification of the prize winner and to provide you
                with marketing, promotional and advertising information about Central District Football Club and Novita
                Services; and
                <br />
                (ii) handled in accordance with Central District Football Club and Novita Services privacy policies
                located at{" "}
                <a target="_new" href="www.cdfclottery.com.au/privacy-policy/">
                  cdfclottery.com.au/privacy-policy/
                </a>
                .<br />
                <strong>(b)</strong> Unless otherwise stated, personal information will not be disclosed to third
                parties, except for the purposes of administering the lottery and the Prize.
                <br />
                (c) By entering the lottery, you consent to:
                <br />
                (i) inclusion of your personal information on our mailing lists. Your ability to opt out of any mailing
                lists is detailed in our privacy policy; and
                <br />
                (ii) our use of your personal information to provide you with marketing, promotional and advertising
                information about Central District Football Club and Novita Services.
                <br />
                <strong>5.4 General</strong>
                <br />
                <strong>(a)</strong> By entering the lottery, you acknowledge and agree that if you are judged as the
                prize winner, Central District Football Club and Novita Services may:
                <br />
                (i) use your name; and
                <br />
                (ii) Interview you, and take your photo, for the marketing, promotional, publicity and other business
                purposes of Central District Football Club and Novita Services, including without limitation, to display
                your name, photo and winning entry on the Central District Football Club and Novita Services Lottery
                website (
                <a target="_new" href="https://cdfclottery.com.au/">
                  https://cdfclottery.com.au/
                </a>
                ) You acknowledge and agree that you will not be entitled to any payment or compensation for such use or
                licence.
                <br />
                <strong>(b)</strong> There may be ancillary costs and expenses associated with participating in the
                lottery and collecting or using a prize, including obtaining a driver’s licence. These costs and
                expenses are your sole responsibility and will be borne by you. Central District Football Club and
                Novita Services accepts no responsibility for any tax implications that may arise from winning a prize
                and where appropriate, independent financial advice should be sought.
                <br />
                <strong>(c)</strong> Notwithstanding that the lottery may be promoted on social media pages, the
                operators of those social media platforms (including Facebook) are in no way sponsoring, endorsing,
                administering or otherwise associated with, the lottery. You release those operators from any claims you
                may have in connection with the lottery
                <br />
                <strong>(d)</strong> If there is any event that prevents or hinders Central District Football Club and
                Novita Services’ conduct of the lottery, it may, in its discretion and subject to applicable laws,
                cancel the lottery and recommence it at another time under the same or other conditions.
                <br />
                <strong>(e)</strong> To the maximum extent permitted by law, you agree to indemnify and hold harmless
                Central District Football Club and Novita Services (and its agents, officers and employees) against all
                losses, proceedings, costs, expenses (including legal fees on a solicitor and own client basis), claims
                and damages arising from your breach of these terms and conditions, your participation in the lottery
                and any component of the awarded Prize.
                <br />
                <strong>(f)</strong> To the maximum extent permitted by law, all conditions, warranties, guarantees,
                rights, remedies, liabilities and other terms which may be implied by statute, custom or the common law
                are excluded from the lottery and these terms and conditions. If any supply under these terms and
                conditions is a supply of goods or services to a consumer within the meaning of the Australian Consumer
                Law, nothing contained in these terms and conditions excludes, restricts or modifies the application of
                any provision, the exercise of any right or remedy, or the imposition of any liability under the
                Australian Consumer Law, provided that, to the extent that the Australian Consumer Law permits the
                Central District Football Club and Novita Services to limit their liability, then Central District
                Football Club and Novita Services liability will be limited to:
                <br />
                (i) in the case of services, the cost of supplying the services again or payment of the cost of having
                the services supplied again; and
                <br />
                (ii) in the case of goods, the cost of replacing the goods, supplying equivalent goods or having the
                goods repaired, or payment of the cost of replacing the goods, supplying equivalent goods or having the
                goods repaired.
                <br />
                <strong>(g)</strong> Except where otherwise required by law (or the rules of any applicable licensing
                authority), the conduct of the lottery and these terms and conditions are governed by the laws of South
                Australia.
                <br />
                <br />
              </p>
              <div id="pdfready2"></div>
            </Col>
          </Row>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Tickets);
