import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'

import _ from 'lodash'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, ProgressBar, Tab, Tabs, Dropdown } from 'react-bootstrap'
import { Line, HorizontalBar, Pie, Radar } from 'react-chartjs-2';
import GoogleMapReact from 'google-map-react'
import GeoHelpers from '../helpers/GeoHelpers'

import '../styles/global.css'

const COLORS = [
    "#000000",
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF"
];

class Analytics extends Component {


    state = {
        loading: true,
        busy: false
    }

    componentDidMount() {

        this._getAnalytics = this._getAnalytics.bind(this)
        this._downloadCsv = this._downloadCsv.bind(this)
        this._formatUtmSourceData = this._formatUtmSourceData.bind(this)
        this._referrerSourceOverview = this._referrerSourceOverview.bind(this)


        if (!this.props.user) {
            this.props.checkUser((u) => {
                if (!this.props.user) {
                    console.log(this.props.user)
                    this.props.history.push("/login")
                } else {
                    this.setState({ loading: false, busy: true }, () => {
                        this._getAnalytics()
                    })
                }
            })
        } else {
            this.setState({ loading: false, busy: true }, () => {
                this._getAnalytics()
            })
        }
    }

    _getAnalytics() {

        let self = this

        //START HACK

        /* const data = dummyData
        let positions = []
        for (var p in data.salesData.postcodes) {
            if (GeoHelpers.postcodes[p] && GeoHelpers.postcodes[p].lat && GeoHelpers.postcodes[p].lon) {
                positions.push({
                    lat: GeoHelpers.postcodes[p].lat,
                    lng: GeoHelpers.postcodes[p].lon,
                    weight: data.salesData.postcodes[p]
                })

            }
        }
        let heatMapData = {
            positions: positions
        }

        let utmSourceData = self._formatUtmSourceData(data.salesData.referrers)

        console.log("utmSourceData", utmSourceData)
        self.setState({
            data: data,
            heatMapData: heatMapData,
            utmSourceData: utmSourceData,
            busy: false
        })

        return */
        
        //END HACK

        

        if (self.props.user.attributes["custom:role"] != "admin" && self.props.user.attributes["custom:role"] != "analytics") {
            this.props.history.push("/")
            return
        }
        self.props.getAnalytics((data) => {
            if (data.error) {
                alert(data.message)
                this.props.history.push("/")
            } else {

                let positions = []
                for (var p in data.salesData.postcodes) {
                    if (GeoHelpers.postcodes[p] && GeoHelpers.postcodes[p].lat && GeoHelpers.postcodes[p].lon) {
                        positions.push({
                            lat: GeoHelpers.postcodes[p].lat,
                            lng: GeoHelpers.postcodes[p].lon,
                            weight: data.salesData.postcodes[p]
                        })

                    }
                }
                let heatMapData = {
                    positions: positions
                }

                let utmSourceData = self._formatUtmSourceData(data.salesData.referrers)

                console.log(heatMapData)
                self.setState({
                    data: data,
                    heatMapData: heatMapData,
                    utmSourceData: utmSourceData,
                    busy: false
                })
            }

        })
    }

    _formatUtmSourceData(rdata) {
        let self = this
        let data = {
            labels: [],
            datasets: []
        }
        if (!rdata) return data

        let darr = []
        for (var key in rdata) {
            rdata[key].date = new Date(key);
            darr.push(rdata[key]);
        }
        darr.sort((a, b) => {
            if (a.date < b.date) {
                return -1;
            }
            if (a.date > b.date) {
                return 1;
            }
            return 0;
        });
        let usedLabels = [];
        for (var i = 0; i < darr.length; i++) {

            data.labels.push(
                darr[i].date.getDate() + "/" + (parseInt(darr[i].date.getMonth())+1)
            );

            for (var label in darr[i].utm_source) {
                
                if (usedLabels.indexOf(label) == -1) {
                    usedLabels.push(label);
                    data.datasets.push({
                        label: label,
                        backgroundColor: COLORS[usedLabels.length - 1],
                        data: []
                    });
                }
            }



        }

        for(var i = 0; i < data.datasets.length; i ++){
            for(var j = 0; j < data.labels.length; j ++){
                data.datasets[i].data.push(0)   
            }
        }

        for (var j = 0; j < darr.length; j++) {
            for (var utm_source in darr[j].utm_source) {                
                for (var k = 0; k < data.datasets.length; k++) {
                    if (data.datasets[k].label == utm_source) {
                        let convertedDate = darr[j].date.getDate() + "/" + (parseInt(darr[j].date.getMonth())+1)
                        let didx = data.labels.indexOf(convertedDate)
                        data.datasets[k].data[didx] = darr[j].utm_source[utm_source]
                    }
                }
            }

        }

        return data
    }

    _referrerSourceOverview() {
        let self = this

        if (!self.state.utmSourceData) return null
        let { utmSourceData } = self.state
        let datasets = utmSourceData.datasets
        console.log("dataSets", utmSourceData)
        let dict = {}
        _.forEach(datasets, (d, i) => {
            console.log(d)
            if (!dict[d.label]) {
                dict[d.label] = 0
                console.log("created", d.label)
            }
            _.forEach(d.data, (d2, i2) => {
                dict[d.label] += d2
            })
        })
        
        let pie = {
            labels:[],
            datasets:[
                {
                    data:[],
                    backgroundColor:[]
                }
            ]
        }
        for (var k in dict) {
            pie.labels.push(k)
            pie.datasets[0].data.push(dict[k])
        }
        
        _.forEach(pie.datasets[0].data, (d,i) => {
            pie.datasets[0].backgroundColor.push(COLORS[i])
        })

        return <Pie data={pie} />




    }

    _downloadCsv() {
        let self = this
        self.setState({ busy: true }, () => {
            self.props.exportPurchases((res) => {
                self.setState({ busy: false })
                if (res && res.csv) {
                    window.open(res.csv, "_blank")
                } else {
                    alert(res.message ? res.message : "Error generating CSV")
                }
            })
        })
    }

    logout = () => {
        this.setState({ busy: true }, () => {
            this.props.logout(() => {
                this.setState({ busy: false }, () => {
                    this.props.history.push("/login")
                })

            })
        })

    }



    render() {
        let self = this
        var logoutBtn = <Button disabled={this.state.busy}
            variant="info"
            onClick={this.logout}>
            {this.state.busy ? <FontAwesomeIcon spin icon={faCircleNotch} />
                : null}
            LOGOUT
            </Button>

        return (
            self.state.loading || self.state.busy ?
                <div className="loaderContainer">
                    <div className="lds-dual-ring"></div>
                </div>
                :
                <Container className="mainContainer">
                    <Row style={{ marginBottom: 20 }}>
                        <Col md={12}>
                            <Button disabled={this.state.busy} onClick={() => this.props.history.push("/")}
                                variant="info">
                                Back to Dashboard
                        </Button>
                            <Button className="float-right" disabled={this.state.busy}
                                onClick={self._downloadCsv}
                                variant="success">
                                Download CSV
                        </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h2>Tickets Sold: {(self.state.data.event.ticketsSold - self.state.data.event.ticketsCancelled)} ({(((self.state.data.event.ticketsSold - self.state.data.event.ticketsCancelled) / self.state.data.event.maxTickets) * 100).toFixed(2)}%)</h2>
                            <ProgressBar now={(self.state.data.event.ticketsSold - self.state.data.event.ticketsCancelled) / self.state.data.maxTickets} max={1} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {self.state.data.salesData ?
                                <div>
                                    <Line data={{
                                        labels: self.state.data.salesData.labels,
                                        datasets: [{
                                            label: "Sales",
                                            data: self.state.data.salesData.data,
                                            backgroundColor: "rgba(0,255,0,0.4)",
                                            borderColor: "rgba(0,255,0,1)"
                                        }
                                        ]

                                    }} />
                                    {self.state.utmSourceData ? <div>

                                        <Tabs defaultActiveKey="source_overview" style={{ marginTop: 50, marginBottom: 50 }}>
                                            <Tab eventKey="source_overview" title="UTM Source Overview">
                                                {self._referrerSourceOverview()}
                                            </Tab>
                                            <Tab eventKey="utm_source" title="UTM Source by Date">
                                                <HorizontalBar data={self.state.utmSourceData} options={{
                                                    scales: {
                                                        yAxes: [
                                                            {
                                                                stacked: true,
                                                                ticks: {
                                                                    beginAtZero: true,
                                                                },
                                                            },
                                                        ],
                                                        xAxes: [
                                                            {
                                                                stacked: true,
                                                            },
                                                        ],
                                                    }
                                                }} />
                                            </Tab>
                                        </Tabs>

                                    </div> : null}
                                    {self.props.user.attributes["custom:role"] == "admin" && self.state.heatMapData ?
                                        <div style={{ marginTop: 50, height: 300, width: '100%' }}>
                                            <GoogleMapReact
                                                ref={(el) => this._googleMap = el}
                                                bootstrapURLKeys={{ apiKey: "AIzaSyDxpAo9T6WhRELYxELpEZ-Tyqoi08ZiCyk" }}
                                                defaultCenter={{
                                                    lat: -34.9285,
                                                    lng: 138.6007
                                                }}
                                                defaultZoom={7}
                                                heatmapLibrary={true}
                                                heatmap={self.state.heatMapData}
                                            /* onClick={this.onMapClick.bind(this)} */
                                            ></GoogleMapReact></div> : null}

                                </div> : null}
                        </Col>
                    </Row>


                </Container>
        )
    }
}

export default withRouter(Analytics)