import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import _ from "lodash";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Button, Carousel, Form, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import Autosuggest from "react-autosuggest";
import StringHelpers from "../helpers/StringHelpers";
import "babel-polyfill";
import PurchaseSummary from "./PurchaseSummary";

import "../styles/global.css";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Sell extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",

    address: "",
    suburb: "",
    postcode: "",
    state: "SA",

    cardName: "",
    cardNumber: "",
    expiryMonth: "01",
    expiryYear: "23",
    ccv: "",

    terms: false,
    optOutOfSMS: false,
    nfp: false,
    optOutOfDM: false,
    age: "",
    subscription: false,
    noEmailSupplied: false,
    printedTicketRequired: false,

    numberOfTickets: 1,

    addresses: [],
    checkingAddress: false,
    addressMessage: "",
    addressConfirmed: false,
    showPurchaseDetailsSummary: false,
    showAddressSelector: false,
    is18: false,

    purchasing: false,

    styles: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",
      address: "react-autosuggest__input",
      suburb: "",
      postcode: "",
      cardName: "",
      cardNumber: "",
      ccv: "",
      terms: "",
      is18: "",
    },

    paymentMethod: "website",

    dataSource: [],
    value: "",

    loading: true,
  };

  done = () => {
    this.props.history.push("/");
  };

  clearInputError(input) {
    if (input == "address") {
      this.setState({
        styles: update(this.state.styles, { [input]: { $set: "react-autosuggest__input" } }),
      });
    } else {
      this.setState({
        styles: update(this.state.styles, { [input]: { $set: "" } }),
      });
    }
  }

  checkAddress = () => {
    this.setState({
      firstName: StringHelpers.titleCase(this.state.firstName),
      lastName: StringHelpers.titleCase(this.state.lastName),
    });

    let self = this;
    var valid = true;
    var errorStyles = this.state.styles;
    let fieldsToCheck = ["firstName", "lastName", "email", "phone", "address", "postcode", "suburb", "is18"];
    _.forEach(fieldsToCheck, function (f) {
      if (f == "is18") {
        if (!self.state[f]) {
          valid = false;
          errorStyles[f] = "errorInput";
        }
      } else if (f == "address") {
        if (self.state[f] == "") {
          valid = false;
          errorStyles[f] += " errorInput";
        }
      } else if (f == "email") {
        if (!self.state.noEmailSupplied) {
          let invalidEmail = emailRegex.test(String(self.state[f]).toLowerCase());
          if (!invalidEmail) {
            valid = false;
            errorStyles[f] = "errorInput";
            errorStyles.email = "errorInput";
            errorStyles.confirmEmail = "errorInput";
          }
          if (self.state[f] != self.state.confirmEmail) {
            valid = false;
            errorStyles[f] = "errorInput";
            errorStyles.confirmEmail = "errorInput";
          }
        }
      } else {
        if (self.state[f] == "") {
          valid = false;
          errorStyles[f] = "errorInput";
        }
      }
    });

    if (!valid) {
      self.setState({
        styles: errorStyles,
      });
      return;
    }

    this.setState({
      addressConfirmed: true,
      showAddressSelector: false,
    });
    /*this.props.checkAddress(this.state.address + " " + this.state.suburb + " " + this.state.state + " " + this.state.postcode, (result) => {
            var addresses = []
            var addressMessage = ""
            var showAddressSelector = false
            var userFormattedAddress = this.state.address + ", " + this.state.suburb + " " + this.state.state + " " + this.state.postcode + ", Australia"


            if (result && result.matched && result.address) {
            
            //if (result && result.results && result.results.length > 0) {
                //addresses = result.results
                addresses = [
                    {
                        formatted_address: result.address.full_address,
                        address_components:{
                            address_line_1: result.address.address_line_1,
                            address_line_2: result.address.address_line_2,
                            suburb: result.address.locality_name,
                            state: result.address.state_territory,
                            postcode: result.address.postcode
                        }
                    }
                ]
                addressMessage = "Please confirm your address"
                showAddressSelector = true

            } else {
                addressMessage = "We could not find exact your address. Please confirm it has been entered correctly."
                addresses = [
                    {
                        formatted_address: userFormattedAddress
                    }
                ]
                showAddressSelector = true
            }

            this.setState({
                checkingAddress: false,
                addresses: addresses,
                addressMessage: addressMessage,
                showAddressSelector: showAddressSelector
            })
        })*/
  };

  updateAddress = (a) => {
    var streetNumber = "";
    var street = "";
    var suburb = "";
    var postcode = "";
    var state = "";
    var address = "";

    if (a.address_components) {
      /*_.forEach(a.address_components, function (t) {
                if (t.types[0] == "street_number") {
                    streetNumber = t.long_name
                } else if (t.types[0] == "route") {
                    street = t.long_name
                } else if (t.types[0] == "locality") {
                    suburb = t.long_name
                } else if (t.types[0] == "administrative_area_level_1") {
                    state = t.short_name
                } else if (t.types[0] == "postal_code") {
                    postcode = t.long_name
                }
            })*/
      address = a.address_components.address_line_1;
      if (a.address_components.address_line_2) {
        address += ", " + a.address_components.address_line_2;
      }
      suburb = a.address_components.suburb;
      postcode = a.address_components.postcode;
      state = a.address_components.state;
    } else {
      address = this.state.address;
      suburb = this.state.suburb;
      postcode = this.state.postcode;
      state = this.state.state;
    }

    this.setState({
      //address: a.address_components ? streetNumber + " " + street : address,
      address: address,
      suburb: suburb,
      postcode: postcode,
      state: state,
      addressConfirmed: true,
      showAddressSelector: false,
    });
  };

  buyTickets = () => {
    let self = this;
    var valid = true;
    var errorStyles = this.state.styles;
    var fieldsToCheck = ["cardName", "cardNumber", "terms"];
    if (this.state.paymentMethod == "website") fieldsToCheck.push("ccv");
    if (this.state.paymentMethod != "website") fieldsToCheck = ["terms"];
    _.forEach(fieldsToCheck, function (f) {
      if (f == "terms") {
        if (!self.state[f]) {
          valid = false;
          errorStyles[f] = "errorInput";
        }
      } else {
        if (self.state[f] == "") {
          valid = false;
          errorStyles[f] = "errorInput";
        }
      }
    });

    if (!valid) {
      self.setState({
        styles: errorStyles,
      });
      return;
    }

    if (self.props.userIsAdmin) {
      self.setState({
        showPurchaseDetailsSummary: true,
      });
    } else {
      self.confirmPurchase(self.state);
    }
  };

  confirmPurchase(details) {
    let self = this;

    details.showPurchaseDetailsSummary = false;
    details.purchasing = true;

    self.setState(details, () => {
      let printedTicketRequired = self.state.printedTicketRequired || self.state.noEmailSupplied;

      let purchaseData = {
        firstName: self.state.firstName,
        lastName: self.state.lastName,
        email: self.state.email == "" ? "tickets@cdfclottery.com.au" : self.state.email,
        phone: self.state.phone,

        address: self.state.address,
        suburb: self.state.suburb,
        postcode: self.state.postcode,
        state: self.state.state,

        cardName: self.state.cardName,
        cardNumber: self.state.cardNumber,
        expiryMonth: self.state.expiryMonth,
        expiryYear: self.state.expiryYear,
        cvc: self.state.paymentMethod == "postal" ? "000" : self.state.ccv,

        ticketCount: self.state.numberOfTickets,

        optOutOfSMS: self.state.optOutOfSMS,
        age: self.state.age,
        subscription: self.state.subscription,
        nfp: self.state.nfp,
        optOutOfDM: self.state.optOutOfDM,

        paymentMethod: self.state.paymentMethod,
        printedTicketRequired: printedTicketRequired,
      };

      self.props.purchase(purchaseData, (result) => {
        self.setState({ purchasing: false });
        if (result.error) {
          alert(result.message);
        } else {
          self.setState({ purchaseId: result.purchaseId, purchaseResult: result, purchaseComplete: true });
        }
      });
    });
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.addressLookup(value.trim(), (result) => {
      if (result && result.success) {
        this.setState({
          dataSource: result.completions,
        });
      } else {
        this.setState({
          dataSource: [],
        });
      }
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      dataSource: [],
    });
  };

  renderSuggestion(suggestion) {
    return <span>{suggestion.full_address}</span>;
  }

  getSuggestionValue(suggestion) {
    return suggestion.full_address;
  }

  selectSuggestion = (event, suggestion) => {
    this.clearInputError("address");
    var suburb = "";
    var postcode = "";
    var state = "";
    var address = "";
    this.props.addressData(suggestion.suggestion.id, (result) => {
      if (result && result.success) {
        address = result.address_line_1;
        if (result.address_line_2) {
          address += ", " + result.address_line_2;
        }
        suburb = result.locality_name;
        postcode = result.postcode;
        state = result.state_territory;
        this.setState({
          address: address,
          suburb: suburb,
          postcode: postcode,
          state: state,
        });
      } else {
        //Do nothing?
      }
    });
  };

  componentDidMount() {
    if (!this.props.user) {
      this.props.checkUser((u) => {
        if (!this.props.user) {
          console.log(this.props.user);
          this.props.history.push("/login");
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    let self = this;

    const inputProps = {
      placeholder: "e.g 1 Smith Street",
      value: this.state.value,
      onChange: this.onChange,
      disabled: this.state.addressConfirmed,
      className: this.state.styles.address,
      autocomplete: "disable-autocomplete",
    };

    if (this.state.loading) {
      return (
        <div className="loaderContainer">
          <div className="lds-dual-ring"></div>
        </div>
      );
    }

    var addressSelector = [];
    if (this.state.showAddressSelector) {
      let addresses = [];
      _.forEach(this.state.addresses, function (a) {
        addresses.push(
          <div>
            <Row>
              <Col xs={6}>
                {a.formatted_address}
                <br />
              </Col>
              <Col xs={6}>
                <Button className="float-right" onClick={() => self.updateAddress(a)}>
                  Confirm
                </Button>
              </Col>
            </Row>
            <hr />
          </div>
        );
      });
      addresses.push(
        <div>
          <hr />
          <Button variant="info" className="float-right" onClick={() => self.setState({ showAddressSelector: false })}>
            Cancel
          </Button>
        </div>
      );
      addressSelector.push(
        <Modal aria-labelledby="contained-modal-title-vcenter" centered show={true}>
          <Modal.Header>
            <Modal.Title>{this.state.addressMessage}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addresses}</Modal.Body>
        </Modal>
      );
    }

    var personalDetails = [];
    if (!this.state.purchaseComplete) {
      personalDetails.push(
        <Row>
          <Col xs={12} className="paddingTop20">
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g John"
                    value={this.state.firstName}
                    onChange={(e) => {
                      this.setState({ firstName: String(e.target.value).replace(/[0-9!@#$%^&*()<>.]/g, "") });
                      this.clearInputError("firstName");
                    }}
                    disabled={this.state.addressConfirmed}
                    className={this.state.styles.firstName}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g Smith"
                    value={this.state.lastName}
                    onChange={(e) => {
                      this.setState({ lastName: String(e.target.value).replace(/[0-9!@#$%^&*()<>.]/g, "") });
                      this.clearInputError("lastName");
                    }}
                    disabled={this.state.addressConfirmed}
                    className={this.state.styles.lastName}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group style={{ marginTop: 7 }}>
                  <label>
                    <Form.Check
                      type="checkbox"
                      style={{ display: "inline-block" }}
                      value={this.state.noEmailSupplied}
                      onChange={(e) => {
                        this.setState({
                          noEmailSupplied: e.target.checked,
                          email: e.target.checked ? "" : this.state.email,
                          confirmEmail: e.target.checked ? "" : this.state.confirmEmail,
                          printedTicketRequired: e.target.checked ? true : this.state.printedTicketRequired,
                        });
                      }}
                      disabled={this.state.addressConfirmed}
                    />
                    No Email Supplied
                  </label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="e.g john@example.com.au"
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                      this.clearInputError("email");
                    }}
                    disabled={this.state.addressConfirmed || this.state.noEmailSupplied}
                    className={this.state.styles.email}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>Confirm Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="e.g john@example.com.au"
                    autoComplete="off"
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    value={this.state.confirmEmail}
                    onChange={(e) => {
                      this.setState({ confirmEmail: e.target.value });
                      this.clearInputError("confirmEmail");
                      this.clearInputError("email");
                    }}
                    disabled={this.state.addressConfirmed || this.state.noEmailSupplied}
                    className={this.state.styles.email}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g 0412345678"
                    value={this.state.phone}
                    onChange={(e) => {
                      this.setState({ phone: String(e.target.value).replace(/[^0-9]+/g, "") });
                      this.clearInputError("phone");
                    }}
                    disabled={this.state.addressConfirmed}
                    className={this.state.styles.phone}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
          </Col>
        </Row>
      );
    }

    var addressBtn = (
      <Button className="blueButton float-right" disabled={this.state.checkingAddress} onClick={this.checkAddress}>
        {this.state.checkingAddress ? <FontAwesomeIcon spin icon={faCircleNotch} /> : null}
        Confirm Details
      </Button>
    );
    if (this.state.addressConfirmed) {
      addressBtn = (
        <Button
          className="blueButton float-right"
          disabled={this.state.checkingAddress}
          onClick={() => this.setState({ addressConfirmed: false })}
        >
          Change Details
        </Button>
      );
    }
    var addressDetails = [];
    if (!this.state.purchaseComplete) {
      addressDetails.push(
        <div>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Autosuggest
                  suggestions={this.state.dataSource}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  onSuggestionSelected={this.selectSuggestion}
                  inputProps={inputProps}
                  className="form-control"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              {!this.state.noEmailSupplied ? (
                <Form.Group style={{ marginTop: 7 }}>
                  <label>
                    <Form.Check
                      type="checkbox"
                      style={{ display: "inline-block" }}
                      onChange={(e) => {
                        this.setState({ printedTicketRequired: e.target.checked });
                      }}
                    />
                    Printed Ticket Required
                  </label>
                </Form.Group>
              ) : (
                <Form.Group style={{ marginTop: 7 }}>
                  <label>
                    <Form.Check type="checkbox" checked disabled style={{ display: "inline-block" }} />
                    Printed Ticket Required
                  </label>
                </Form.Group>
              )}
            </Col>
            <Col md={3}>
              <Form.Group style={{ marginTop: 7 }}>
                <label>
                  <Form.Check
                    type="checkbox"
                    style={{ display: "inline-block" }}
                    onChange={(e) => {
                      this.setState({ optOutOfSMS: e.target.checked });
                    }}
                    disabled={this.state.addressConfirmed}
                  />
                  Opt out of SMS alerts
                </label>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group style={{ marginTop: 7 }}>
                <label className={this.state.styles.is18}>
                  <Form.Check
                    type="checkbox"
                    style={{ display: "inline-block" }}
                    disabled={this.state.addressConfirmed}
                    onChange={(e) => {
                      this.setState({ is18: e.target.checked });
                      this.clearInputError("is18");
                    }}
                  />
                  I am 18 years of age
                </label>
              </Form.Group>
            </Col>

            <Col md={2}>
              <Form.Group>
                <div style={{ display: "inline-block", marginRight: 10 }}>Age</div>
                <Form.Control
                  as="select"
                  style={{ display: "inline-block", width: "auto" }}
                  onChange={(e) => {
                    this.setState({ age: e.target.value });
                  }}
                  disabled={this.state.addressConfirmed}
                >
                  <option value="-">-</option>
                  <option value="18-24">18 - 24</option>
                  <option value="25-34">25 - 34</option>
                  <option value="35-44">35 - 44</option>
                  <option value="45-54">45 - 54</option>
                  <option value="55-64">55 - 64</option>
                  <option value="65+">65 or more</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group style={{ marginTop: 7 }}>
                <label>
                  <Form.Check
                    type="checkbox"
                    style={{ display: "inline-block" }}
                    onChange={(e) => {
                      this.setState({ nfp: e.target.checked });
                    }}
                    disabled={this.state.addressConfirmed}
                  />
                  Name not for publication
                </label>
              </Form.Group>
            </Col>
            {/* <Col md={3}>
                            <Form.Group style={{ marginTop: 7 }}>
                                <label ><Form.Check type="checkbox"
                                    style={{ display: "inline-block" }}
                                    onChange={(e) => {
                                        this.setState({ optOutOfDM: e.target.checked });
                                    }}
                                    disabled={this.state.addressConfirmed} />Opt out of DM</label>
                            </Form.Group>
                        </Col> */}
          </Row>
          <hr />
          <Row>
            <Col md={12}>{addressBtn}</Col>
          </Row>
          <hr />
          {this.state.addressConfirmed &&
          (!self.props.user.attributes["custom:onlineticketsonly"] ||
            self.props.user.attributes["custom:onlineticketsonly"] == "false") ? (
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Payment Method</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.paymentMethod}
                    onChange={(e) => this.setState({ paymentMethod: e.target.value })}
                  >
                    <option value="website">Online</option>
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="cardReader">Card Reader</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          ) : null}
        </div>
      );
    }

    var cardDetails = [];
    if (
      !this.state.purchaseComplete &&
      this.state.addressConfirmed &&
      (this.state.paymentMethod == "website" || this.state.paymentMethod == "postal")
    ) {
      cardDetails.push(
        <div>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Name on Credit Card</Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.cardName}
                      onChange={(e) => {
                        this.setState({ cardName: e.target.value });
                        this.clearInputError("cardName");
                      }}
                      className={this.state.styles.cardName}
                      disabled={this.state.purchasing}
                      placeholder="e.g John Smith"
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Credit Card Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.cardNumber}
                      onChange={(e) => {
                        this.setState({ cardNumber: e.target.value });
                        this.clearInputError("cardNumber");
                      }}
                      className={this.state.styles.cardNumber}
                      disabled={this.state.purchasing}
                      placeholder="e.g 4200000000000000"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Expiry Month</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        this.setState({ expiryMonth: e.target.value });
                      }}
                      disabled={this.state.purchasing}
                    >
                      <option value="01">Jan</option>
                      <option value="02">Feb</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Expiry Year</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        this.setState({ expiryYear: e.target.value });
                      }}
                      disabled={this.state.purchasing}
                    >
                      <option value="23">2023</option>
                      <option value="24">2024</option>
                      <option value="25">2025</option>
                      <option value="26">2026</option>
                      <option value="27">2027</option>
                      <option value="28">2028</option>
                      <option value="29">2029</option>
                      <option value="30">2030</option>
                      <option value="31">2031</option>
                      <option value="32">2032</option>
                      <option value="33">2033</option>
                      <option value="34">2034</option>
                      <option value="35">2035</option>
                      <option value="36">2036</option>
                      <option value="37">2037</option>
                      <option value="38">2038</option>
                      <option value="39">2039</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

                {this.state.paymentMethod == "website" ? (
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>CCV</Form.Label>
                      <Form.Control
                        type="text"
                        value={this.state.ccv}
                        onChange={(e) => {
                          this.setState({ ccv: e.target.value });
                          this.clearInputError("ccv");
                        }}
                        className={this.state.styles.ccv}
                        disabled={this.state.purchasing}
                        placeholder="e.g 333"
                      />
                    </Form.Group>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          <hr />
        </div>
      );
    }

    var submitBtn = (
      <Button className="greenButton float-right" disabled={this.state.purchasing} onClick={this.buyTickets}>
        {this.state.purchasing ? <FontAwesomeIcon spin icon={faCircleNotch} /> : null}
        SUBMIT
      </Button>
    );
    var submitDetails = [];
    if (!this.state.purchaseComplete && this.state.addressConfirmed) {
      submitDetails.push(
        <div className="paddingBottom20">
          <Row>
            <Col md={6}>
              <Form.Group>
                <label className={this.state.styles.terms}>
                  <Form.Check
                    type="checkbox"
                    style={{ display: "inline-block" }}
                    disabled={this.state.purchasing}
                    onChange={(e) => {
                      this.setState({ terms: e.target.checked });
                      this.clearInputError("terms");
                    }}
                  />
                  I agree to the{" "}
                  <a href="/images/WCH Foundation Lottery Terms & Conditions 2020.pdf" target="_blank">
                    terms and conditions
                  </a>
                </label>
              </Form.Group>
            </Col>
            <Col md={6}>
              {submitBtn}
              {this.props.purchaseError ? <p className="errorInput">{this.props.purchaseError}</p> : null}
            </Col>
          </Row>
        </div>
      );
    }

    var selectTickets = [];
    if (!this.state.purchaseComplete) {
      selectTickets.push(
        <Row className="light-green-bg">
          <Col xs={12}>
            <Row className="paddingTop20">
              <Col md={12}>
                <h3 style={{ fontSize: 24 }}>Ticket Order Form</h3>
              </Col>
            </Row>
            <Row className="select-tickets">
              <Col sm={4} md={3} xs={12}>
                <Button
                  className="number-of-tickets-btn"
                  onClick={() =>
                    this.setState({
                      numberOfTickets: this.state.numberOfTickets - (this.state.numberOfTickets == 1 ? 0 : 1),
                    })
                  }
                >
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
                <div className="number-of-tickets">
                  {this.state.numberOfTickets} Ticket{this.state.numberOfTickets == 1 ? "" : "s"}
                </div>
                <Button
                  className="number-of-tickets-btn"
                  onClick={() => this.setState({ numberOfTickets: this.state.numberOfTickets + 1 })}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
              <Col xs={12} sm={6} className="number-of-tickets">
                Total Amount:{" "}
                <span className="blueText" style={{ fontWeight: 600 }}>
                  ${this.state.numberOfTickets * 50}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

    var thankyou = [];
    if (this.state.purchaseComplete) {
      let p = this.state.purchaseResult;
      let ticketStr = "";
      for (let i = p.ticketIndex; i < p.ticketIndex + p.ticketCount; i++) {
        if (ticketStr != "") ticketStr += ", ";
        ticketStr += "CDFC001_" + StringHelpers.pad(i, 5);
        console.log(i);
      }
      thankyou.push(
        <Row className="paddingTop20 paddingBottom20">
          <Col md={12}>
            <h3 style={{ fontSize: 24 }}>Ticket Details &amp; Order Summary</h3>
            <p>
              <strong>Name</strong>: {this.state.firstName} {this.state.lastName}
              <br />
              <strong>Address</strong>: {this.state.address}, {this.state.suburb} {this.state.state}{" "}
              {this.state.postcode}
              <br />
              <strong>Email</strong>: {this.state.email}
              <br />
              <strong>Phone</strong>: {this.state.phone}
              <br />
              <strong>Number of tickets purchased</strong>: {this.state.numberOfTickets}
              <br />
              <strong>Ticket Numbers</strong>: {ticketStr}
              <br />
              <strong>Total amount</strong>: ${Number(this.state.numberOfTickets) * 50}
              <br />
              <strong>Receipt Number</strong>: {this.state.purchaseId}
              <br />
            </p>
            <p>
              The official {Number(this.state.numberOfTickets) > 1 ? "tickets have" : "ticket has"} been emailed to{" "}
              {this.state.noEmailSupplied ? "(NO EMAIL SUPPLIED)" : this.state.email}.
            </p>

            <Button variant="success" onClick={this.done}>
              Done
            </Button>
          </Col>
        </Row>
      );
    }

    let purchaseDetailsSummary = this.state.showPurchaseDetailsSummary ? (
      <PurchaseSummary
        details={this.state}
        confirmPurchase={(details) => {
          self.confirmPurchase(details);
        }}
        cancel={() => {
          this.setState({ showPurchaseDetailsSummary: false });
        }}
      />
    ) : null;

    return (
      <Container className="mainContainer">
        <Row style={{ marginBottom: 20 }}>
          <Button disabled={this.state.busy} onClick={() => this.props.history.push("/")} variant="info">
            Back to Dashboard
          </Button>
        </Row>

        {purchaseDetailsSummary}
        {addressSelector}
        {selectTickets}
        <Form>
          {personalDetails}
          {addressDetails}
          {cardDetails}
          {submitDetails}
          {thankyou}
        </Form>
      </Container>
    );
  }
}

export default withRouter(Sell);
