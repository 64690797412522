import _ from 'lodash';
import aws4 from "aws4"
import Amplify from 'aws-amplify'
import Auth from '@aws-amplify/auth'

export const VERSION_NUMBER = "2.0"
export const BUILD_NUMBER = "0.0.2"

const isLocal = false//window.location.href.indexOf("sandbox") > -1



// AWS Amplify and API
const API_ENDPOINT = isLocal ? "127.0.0.1:3000" : "api.cdfclottery.com.au"
const API_STAGE = "/"
const API_PROTOCOL = isLocal ? "http://" : "https://"
const API_REGION = "ap-southeast-2"
let currentUser = null

Amplify.configure({
    Auth: {

        identityPoolId: 'ap-southeast-2:64537804-5255-497a-8d14-b529c2d8e5b6',
        region: API_REGION,
        userPoolId: 'ap-southeast-2_QmzrKpmO1',
        userPoolWebClientId: '7vubls255jl5gbhe1sbf749e96',
        mandatorySignIn: false,
        cookieStorage: {
            domain: isLocal ? 'sandbox.cdfclottery.com.au' : 'admin.cdfclottery.com.au',
            path: '/',
            expires: 30,
            secure: false
        }
    }
});

/* Auth methods */

function onSignIn(data) {
/* 
    ReactGA.set({
        userId: data.id
    }) */

    return {
        type: 'SIGN_IN',
        data: data,
        receivedAt: Date.now()
    }
}

function onSignOut() {
    return {
        type: 'SIGN_OUT',
        data: null,
        receivedAt: Date.now()
    }
}

export function signOut() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise((resolve, reject) => {
            Auth.signOut().
                then((user) => {
                    console.log("Signout successful")
                    currentUser = null
                    dispatch(onSignOut())
                    resolve()
                })
                .catch((err) => {
                    console.log("Signout failed", err)
                    currentUser = null
                    dispatch(onSignOut(null))
                    resolve()
                }
                );

        })


    }
}

export function reqestPasswordReset(email) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise((resolve, reject) => {
            Auth.forgotPassword(email)
                .then(data => {
                    resolve({ success: true })
                })
                .catch(err => {
                    resolve(false)
                });
        })
    }
}

export function confirmPasswordReset(email, password, code) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise((resolve, reject) => {
            Auth.forgotPasswordSubmit(email, code, password)
                .then(data => {
                    resolve({ success: true })
                })
                .catch(err => {
                    resolve(false)
                });
        })
    }
}

function onUpdateUser(data) {
    return {
        type: 'ON_UPDATE_USER',
        data: data,
        receivedAt: Date.now()
    }
}

export function checkUserSession() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise(async (resolve, reject) => {

            if (currentUser) {
                dispatch(onUpdateUser(currentUser))
                resolve()
            } else {

                let response = await _updateUser()
                console.log("user updated", currentUser)
                if (response) {
                    dispatch(onUpdateUser(currentUser))
                    dispatch({ type: 'API_IDLE' })
                    resolve()
                } else {
                    dispatch(onUpdateUser(null))
                    dispatch({ type: 'API_IDLE' })
                    resolve()
                }
            }
        })
    }
}

export function updateUser() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise(async (resolve, reject) => {

            let response = await _updateUser()
            if (response) {
                dispatch(onUpdateUser(currentUser))
                dispatch({ type: 'API_IDLE' })
                resolve(true)
            } else {
                dispatch(onUpdateUser(null))
                dispatch({ type: 'API_IDLE' })
                resolve(false)
            }
        })
    }
}

async function _updateUser() {
    return new Promise(async (resolve, reject) => {

        Auth.currentAuthenticatedUser()
            .then(async (data) => {
                currentUser = data
                resolve(true)
            })
            .catch((err) => {
                console.log("ERROR", err)
                resolve(false)
            });
    })
}


export function signIn(username, password) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise(async (resolve, reject) => {

            Auth.signIn(username, password).
                then(async (user) => {
                    console.log(user)

                    currentUser = user
                    let response = await _updateUser()
                    dispatch(onSignIn(currentUser))
                    resolve({ success: true })
                })
                .catch(async (err) => {
                    console.log("signIn", err)
                    if (err.code == "UserNotConfirmedException") {
                        await resendVerification(username)
                        resolve({ success: false, message: "Looks like your email address needs to be verified. A verification code has been sent to " + username, code: err.code })
                        dispatch({ type: 'API_IDLE' })
                    } else {
                        resolve({ success: false, message: err.message, code: err.code })
                        dispatch({ type: 'API_IDLE' })
                    }


                }
                );

        })

    }
}

export function verifyEmail(email, code) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise(async (resolve, reject) => {

            Auth.confirmSignUp(email, code).
                then(async (data) => {
                    console.log(data)
                    dispatch({ type: 'API_IDLE' })
                    resolve({ success: true })
                })
                .catch((err) => {
                    console.log("verifyEmail", err)
                    dispatch({ type: 'API_IDLE' })
                    resolve({ success: false, message: err.message })
                }
                );

        })
    }
}

export function resendVerification(email) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        return new Promise(async (resolve, reject) => {

            Auth.resendSignUp(email)
                .then((data) => {
                    console.log("resendVerification success", data)
                    dispatch({ type: 'API_IDLE' })
                    resolve({ success: true, message: "A verification code has been sent" })
                }).catch((err) => {
                    console.log("resendVerification error", err)
                    dispatch({ type: 'API_IDLE' })
                    resolve({ success: false, message: err.message })
                });

        })
    }
}

/* API methods */

function onGetData(type, data) {

    return {
        type: type,
        data: data,
        receivedAt: Date.now()
    }
}

export function getData(path, type, queryString = "", signRequest = false) {

    return function (dispatch) {

        dispatch({ type: 'API_LOADING' })

        return new Promise(async (resolve, reject) => {
            let response = await _getData(path, type, queryString, signRequest)
            if (response) {
                dispatch(onGetData(type, response))
                resolve(response)
            } else {
                dispatch(onGetData(type, null))
                resolve(false)
            }
        })

    }

}

async function _getData(path, type, queryString = "", signRequest = false) {
    let promise = signRequest ?
        new Promise(async (resolve, reject) => {

            var options = {
                method: "GET",
                service: "execute-api",
                region: API_REGION,
                host: API_ENDPOINT,
                path: path + queryString,
                url: API_PROTOCOL + API_ENDPOINT + path + queryString,
            }
            console.log(options)
            const credentials = await Auth.currentCredentials();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials;
            const request = aws4.sign(options, {
                accessKeyId,
                secretAccessKey,
                sessionToken
            });
            delete request.headers.Host;
            console.log(credentials)
            console.log(request.headers)
            let res
            try {
                res = await fetch(options.url, {
                    headers: request.headers
                })
            } catch (e) {
                console.log("Error fetching, ", e)
                window.open("/")
            }

            if (!res || !res.ok) {
                console.log("error with getData", type, res)
                resolve(false);
            } else {
                console.log("res", res)
                //const data = await res.text()
                let data = await res.text()
                data = JSON.parse(data)
                if (data) {
                    console.log(data)
                    resolve(data);
                } else {
                    resolve(false);
                }
            }
        })

        :

        new Promise(async (resolve, reject) => {
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + path + queryString)
            if (!res || !res.ok) {
                console.log("error with getData", type, res)
                resolve(false);
            } else {
                console.log("res", res)
                let data
                try {
                    data = await res.json()
                    if (data) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                } catch (e) {
                    console.log("Error", e)
                    resolve(false)
                }
            }
        })

    return promise
}

function onPostData(type, data) {

    return {
        type: type,
        data: data,
        receivedAt: Date.now()
    }
}

export function postData(path, type, data, signRequest = false) {
    return function (dispatch) {

        dispatch({ type: 'API_LOADING' })

        return new Promise(async (resolve, reject) => {
            let response = await _postData(path, type, data, signRequest)
            if (response) {
                dispatch(onPostData(type, response))
                resolve(response)
            } else {
                resolve(false)
            }
        })

    }
}

async function _postData(path, type, data, signRequest = false) {
    let promise = signRequest ?
        new Promise(async (resolve, reject) => {

            var options = {
                method: "POST",
                service: "execute-api",
                region: API_REGION,
                host: API_ENDPOINT,
                path: path,
                url: API_PROTOCOL + API_ENDPOINT + path,
                body: JSON.stringify(data)
            }
            const credentials = await Auth.currentCredentials();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials;
            const request = aws4.sign(options, {
                accessKeyId,
                secretAccessKey,
                sessionToken
            });
            delete request.headers.Host;
            console.log(credentials)
            console.log(request.headers)
            const res = await fetch(options.url, {
                method: "POST",
                headers: request.headers,
                body: JSON.stringify(data)
            })
            if (!res || !res.ok) {
                console.log("error with postData", type, res)
                resolve(false);
            } else {
                console.log("res", res)
                let data = await res.text()
                data = JSON.parse(data)
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            }
        })

        :

        new Promise(async (resolve, reject) => {

            const res = await fetch(API_PROTOCOL + API_ENDPOINT + path,
                {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                }
            )
            if (!res || !res.ok) {
                console.log("error with getData", res)
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    resolve(data);
                } else {
                    resolve(false);
                }
            }


        })

    return promise
}

export function checkAddress(addressStr) {
    //let apiKey = "AIzaSyDxpAo9T6WhRELYxELpEZ-Tyqoi08ZiCyk"
    //let url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + addressStr + "&key=" + apiKey

    let key = "6KGTWAJVLC4R9U3MHDXB";
    let url = "https://api.addressfinder.io/api/au/address/cleanse?key=" + key + "&q=" + addressStr + "&format=json";

    return function (dispatch) {
        return new Promise((resolve, reject) => {

            var req = async () => {
                try {

                    const rawResponse = await fetch(url, {
                        method: 'GET'
                    });
                    const content = await rawResponse.json();
                    return content

                } catch (err) {
                    console.error("checkAddress error", err)
                    resolve(null)
                }
            };

            req().then(response => {
                console.log("checkAddress response", response)
                resolve(response)
            })

        })
    }
}

export function addressData(id) {
    //let apiKey = "AIzaSyDxpAo9T6WhRELYxELpEZ-Tyqoi08ZiCyk"
    //let url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + addressStr + "&key=" + apiKey
    let key = "6KGTWAJVLC4R9U3MHDXB";
    let url = "https://api.addressfinder.io/api/au/address/info?key=" + key + "&id=" + id + "&format=json";

    return function (dispatch) {
        return new Promise((resolve, reject) => {

            var req = async () => {
                try {

                    const rawResponse = await fetch(url, {
                        method: 'GET'
                    });
                    const content = await rawResponse.json();
                    return content

                } catch (err) {
                    console.error("addressData error", err)
                    resolve(null)
                }
            };

            req().then(response => {
                console.log("addressData response", response)
                resolve(response)
            })

        })
    }
}

export function addressLookup(value) {
    let key = "6KGTWAJVLC4R9U3MHDXB";
    let url = "https://api.addressfinder.io/api/au/address/autocomplete?key=" + key + "&q=" + value + "&format=json";

    return function (dispatch) {
        return new Promise((resolve, reject) => {
            var req = async () => {
                try {
                    const rawResponse = await fetch(url, {
                        method: 'GET'
                    });
                    const content = await rawResponse.json();
                    return content

                } catch (err) {
                    console.error("checkAddress error", err)
                    resolve(null)
                }
            };
            req().then(response => {
                console.log("checkAddress response", response)
                resolve(response)
            })
        })
    }
}