import { connect } from 'react-redux';
import Tickets from '../components/Tickets';
import * as api from '../store/api'

const mapStateToProps = (state) => {
    return {
        apiStatus: state.service.apiStatus,
        user: state.service.user,
        event: state.service.event,
        userIsAdmin: state.service.userIsAdmin,
        salesSearchResults: state.service.salesSearchResults
    };
}


const mapDispatchToProps = (dispatch) => ({
    checkUser: (callback) => {
        dispatch(api.checkUserSession()).then(callback)
    },
    searchPurchases: (searchObj, callback) => {
        dispatch(api.postData("/searchpurchases", "SEARCH_PURCHASES", searchObj, true)).then(callback)
    }    
})


export default connect(mapStateToProps, mapDispatchToProps)(Tickets)
