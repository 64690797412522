import { connect } from 'react-redux';
import ManageSales from '../components/ManageSales';
import * as api from '../store/api'

const mapStateToProps = (state) => {
    return {
        apiStatus: state.service.apiStatus,
        user: state.service.user,
        event: state.service.event,
        userIsAdmin: state.service.userIsAdmin,
        salesSearchResults: state.service.salesSearchResults
    };
}


const mapDispatchToProps = (dispatch) => ({
    checkUser: (callback) => {
        dispatch(api.checkUserSession()).then(callback)
    },
    searchPurchases: (searchObj, callback) => {
        dispatch(api.postData("/searchpurchases", "SEARCH_PURCHASES", searchObj, true)).then(callback)
    },
    updatePurchase: (purchase, callback) => {
        dispatch(api.postData("/editpurchase", "EDIT_PURCHASE", purchase, true)).then(callback)
    },
    cancelPurchase: (purchase, callback) => {
        dispatch(api.postData("/cancelpurchase", "CANCEL_PURCHASE", purchase, true)).then(callback)
    },
    reinstatePurchase: (purchase, callback) => {
        dispatch(api.postData("/reinstatepurchase", "REINSTATE_PURCHASE", purchase, true)).then(callback)
    },
    resendTickets: (purchase, callback) => {
        dispatch(api.postData("/resendtickets", "RESEND_TICKETS", purchase, true)).then(callback)
    },
    clearSearch: () => {
        dispatch({ type: "CLEAR_PURCHASE_SEARCH" });
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(ManageSales)
