import { connect } from 'react-redux';
import Analytics from '../components/Analytics';
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    apiStatus: state.service.apiStatus,
    user: state.service.user,
    events: state.service.events,

  };
}


const mapDispatchToProps = (dispatch) => ({
  logout: (callback) => {
    dispatch(api.signOut()).then(callback)
  },
  checkUser: (callback) => {
    dispatch(api.checkUserSession()).then(callback)
  },
  getAnalytics: (callback) => {
    dispatch(api.getData("/getanalytics", "GET_ANALYTICS", "", true)).then(callback)
  },
  exportPurchases: (callback) => {
    dispatch(api.postData("/exportpurchases", "EXPORT_PURCHASES", "", true)).then(callback)
  }

})


export default connect(mapStateToProps, mapDispatchToProps)(Analytics)
