import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'

import _ from 'lodash'
import update from 'immutability-helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Button, Alert, Form, Modal, Dropdown, Table } from 'react-bootstrap'
import Autosuggest from 'react-autosuggest';
import StringHelpers from '../helpers/StringHelpers'
import 'babel-polyfill';
import moment from 'moment';

import '../styles/global.css'

const editableFields = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "address",
    "suburb",
    "postcode",
    "state",
    "nfp",
    "printedTicketRequired",
    "optOutOfSMS",
    "optOutOfDM",
    "subscription"
]

class ManageSales extends Component {

    state = {
        searchType: "Purchase ID",
        searching: false,
        editPurchase: null,
        resending: false,

        purchaseId: "",
        email: "",
        mobileNumber: "",

        salesSearchResults: null
    }
    componentDidMount() {
        if (!this.props.user) {
            this.props.checkUser((u) => {
                if (!this.props.user) {
                    console.log(this.props.user)
                    this.props.history.push("/login")
                } else {
                    this.setState({ loading: false })
                }
            })
        } else {
            this.setState({ loading: false })
        }

    }

    search = () => {
        var self = this
        var searchOn = "purchaseId"
        var searchTerm = self.state.purchaseId
        switch (self.state.searchType) {
            case "Email":
                searchOn = "email"
                searchTerm = self.state.email
                break
            case "Mobile":
                searchOn = "phone"
                searchTerm = self.state.mobileNumber
                break
        }

        self.setState({
            searching: true
        }, () => {
            self.props.searchPurchases({ searchOn: searchOn, searchTerm: searchTerm }, (data) => {
                self.setState({ searching: false, salesSearchResults: data })
            })
        })
    }

    savePurchase = () => {

        var self = this

        self.setState({
            searching: true
        }, () => {
            self.props.updatePurchase(self.state.editPurchase, () => {
                self.setState({ searching: false, saved: true, editPurchase: null, purchaseId: "", salesSearchResults: null })
                setTimeout(function () {
                    self.setState({ saved: false })
                }, 1500)
            })
        })
    }

    cancelPurchase = () => {

        var self = this

        if (window.confirm("Are you sure you want to cancel this ticket and remove it from the draw?")) {

            self.setState({
                searching: true
            }, () => {
                self.props.cancelPurchase(self.state.editPurchase, () => {
                    self.setState({ searching: false, saved: true, editPurchase: null, purchaseId: "", salesSearchResults: null })
                    setTimeout(function () {
                        self.setState({ saved: false })
                    }, 1500)
                })
            })
        }
    }

    reinstatePurchase = () => {

        var self = this

        if (window.confirm("Are you sure you want to reinstate this ticket and enter it back in the draw?")) {

            self.setState({
                searching: true
            }, () => {
                self.props.reinstatePurchase(self.state.editPurchase, () => {
                    self.setState({ searching: false, saved: true, editPurchase: null, purchaseId: "", salesSearchResults: null })
                    setTimeout(function () {
                        self.setState({ saved: false })
                    }, 1500)
                })
            })
        }
    }

    resendTickets = (purchase) => {
        var self = this

        self.setState({
            ["resending_" + purchase.purchaseId]: true
        }, () => {
            self.props.resendTickets({ purchaseId: purchase.purchaseId }, () => {
                self.setState({ ["resending_" + purchase.purchaseId]: false })
            })
        })
    }



    render() {
        let self = this

        var searchFields = null

        switch (self.state.searchType) {
            case "Purchase ID":
                searchFields = <Form className="marginAbove">
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control value={self.state.purchaseId} type="text" placeholder="Enter Purchase ID" onChange={(e) => { self.setState({ purchaseId: e.target.value }) }} />
                        <Form.Text className="text-muted">
                            E.g aVtEYoFm5k
                  </Form.Text>
                    </Form.Group>
                </Form>
                break
            case "Email":
                searchFields = <Form className="marginAbove">
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control value={self.state.email} type="text" placeholder="Enter Email Address" onChange={(e) => { self.setState({ email: e.target.value }) }} />
                    </Form.Group>
                </Form>
                break
            case "Mobile":
                searchFields = <Form className="marginAbove">
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control value={self.state.mobileNumber} type="text" placeholder="Enter Mobile Number" onChange={(e) => { self.setState({ mobileNumber: e.target.value }) }} />
                    </Form.Group>
                </Form>
                break
        }

        // turning this off for now
        var searchOptions = <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Search by {self.state.searchType}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => { this.setState({ searchType: "Purchase ID" }) }}>Purchase ID</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.setState({ searchType: "Email" }) }}>Email Address</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.setState({ searchType: "Mobile" }) }}>Mobile Number</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

        var results = null

        if (self.state.salesSearchResults) {
            var purchaseList = null
            if (self.state.salesSearchResults.length > 0) {
                purchaseList = []
                for (let i = 0; i < self.state.salesSearchResults.length; i++) {
                    const purchase = self.state.salesSearchResults[i];
                    purchaseList.push(
                        <tr>
                            <td>{purchase.purchaseId}</td>
                            <td>{purchase.firstName}</td>
                            <td>{purchase.lastName}</td>
                            <td>{purchase.seller ? purchase.seller : "Online Sale"}</td>
                            <td>{moment(purchase.dateCreated).format("MMM Do YYYY, HH:mma")}</td>
                            {/* <td>{purchase.purchaseId}_001{purchase.ticketCount > 1 ? " - " + purchase.purchaseId + "_" + StringHelpers.pad(purchase.ticketCount, 3) : ""}</td> */}
                            <td>CDFC001_{purchase.ticketRangeStart}{purchase.ticketCount == 1 ? "" : " - CDFC001_" + purchase.ticketRangeEnd}</td>
                            <td>
                                <Button disabled={this.state["resending_" + purchase.purchaseId]} variant="warning" onClick={() => {

                                    for (let j = 0; j < editableFields.length; j++) {
                                        purchase[editableFields[j]] = purchase[editableFields[j]]
                                    }

                                    self.setState({ editPurchase: purchase })
                                }}>Edit</Button>
                            </td>
                            <td>
                                <Button variant="success" disabled={this.state["resending_" + purchase.purchaseId]} onClick={() => { self.resendTickets(purchase) }}>
                                    {this.state["resending_" + purchase.purchaseId] ? <FontAwesomeIcon spin icon={faCircleNotch} />
                                        : null}Resend Tickets</Button>
                            </td>
                        </tr>
                    )
                }
            } else {
                purchaseList = "No results were found."
            }
            results = self.state.salesSearchResults.length > 0 ? <div>
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Purchase ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Seller</th>
                            <th>Purchase Date</th>
                            <th>Tickets</th>
                            <th>Edit</th>
                            <th>Resend Tickets</th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchaseList}
                    </tbody>
                </Table>
            </div> : <div><hr />{purchaseList}</div>
        }

        var searchBtn = <Button className="float-right" variant="warning" disabled={this.state.searching} onClick={this.search}>
            {this.state.searching ? <FontAwesomeIcon spin icon={faCircleNotch} />
                : null}
            Search
    </Button>

        var saveBtn = <Button variant="success" disabled={this.state.searching} onClick={self.savePurchase}>
            {this.state.searching ? <FontAwesomeIcon spin icon={faCircleNotch} />
                : null}Save changes</Button>
        var cancelOrReinstatePurchaseBtn =  (self.props.user.attributes["custom:role"] != "admin") ? null :
        this.state.editPurchase ?
            (this.state.editPurchase.valid ?
                <Button variant="danger" disabled={this.state.searching} onClick={self.cancelPurchase}>
                    {this.state.searching ? <FontAwesomeIcon spin icon={faCircleNotch} />
                        : null}Cancel Purchase</Button>
                :
                <Button variant="danger" disabled={this.state.searching} onClick={self.reinstatePurchase}>
                    {this.state.searching ? <FontAwesomeIcon spin icon={faCircleNotch} />
                        : null}Reinstate Purchase</Button>) : null


        var editModal = self.state.editPurchase ?
            <Modal backdrop="static" keyboard={false} show={self.state.editPurchase != null} onHide={() => self.setState({ editPurchase: null })} size="lg">
                <Modal.Header>
                    <Modal.Title>Edit Purchase</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Purchase ID: {self.state.editPurchase.purchaseId}<br />Purchase Date: {moment(self.state.editPurchase.dateCreated).format("MMM Do YYYY, HH:mma")}</p>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                First Name
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter first name"
                                value={self.state.editPurchase.firstName}
                                disabled={this.state.searching}
                                onChange={(e) => {
                                    self.setState({
                                        editPurchase: update(self.state.editPurchase, { firstName: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Last Name
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter last name"
                                value={self.state.editPurchase.lastName}
                                disabled={this.state.searching}
                                onChange={(e) => {
                                    self.setState({
                                        editPurchase: update(self.state.editPurchase, { lastName: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Email
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter email"
                                value={self.state.editPurchase.email}
                                disabled={this.state.searching}
                                onChange={(e) => {
                                    self.setState({
                                        editPurchase: update(self.state.editPurchase, { email: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Mobile Number
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter mobile number"
                                value={self.state.editPurchase.phone}
                                disabled={this.state.searching}
                                onChange={(e) => {
                                    self.setState({
                                        editPurchase: update(self.state.editPurchase, { phone: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Address
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter address"
                                value={self.state.editPurchase.address}
                                disabled={this.state.searching}
                                onChange={(e) => {
                                    self.setState({
                                        editPurchase: update(self.state.editPurchase, { address: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Suburb
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter suburb"
                                value={self.state.editPurchase.suburb}
                                disabled={this.state.searching}
                                onChange={(e) => {
                                    self.setState({
                                        editPurchase: update(self.state.editPurchase, { suburb: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                Postcode
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter postcode"
                                value={self.state.editPurchase.postcode}
                                disabled={this.state.searching}
                                onChange={(e) => {
                                    self.setState({
                                        editPurchase: update(self.state.editPurchase, { postcode: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Text className="text-muted">
                                State
                            </Form.Text>
                            <Form.Control type="text" placeholder="Enter state"
                                value={self.state.editPurchase.state}
                                disabled={this.state.searching}
                                onChange={(e) => {
                                    self.setState({
                                        editPurchase: update(self.state.editPurchase, { state: { $set: e.target.value } })
                                    })
                                }}
                            />
                        </Form.Group>

                        <Form.Check
                            type="checkbox"
                            label="Not for publication"
                            checked={self.state.editPurchase.nfp}
                            disabled={this.state.searching}
                            onChange={(e) => {
                                self.setState({
                                    editPurchase: update(self.state.editPurchase, { nfp: { $set: e.target.checked } })
                                })
                            }}
                        />

                        <Form.Check
                            type="checkbox"
                            label="Printed ticket required"
                            checked={self.state.editPurchase.printedTicketRequired}
                            disabled={this.state.searching}
                            onChange={(e) => {
                                self.setState({
                                    editPurchase: update(self.state.editPurchase, { printedTicketRequired: { $set: e.target.checked } })
                                })
                            }}
                        />

                        <Form.Check
                            type="checkbox"
                            label="Out out of SMS"
                            checked={self.state.editPurchase.optOutOfSMS}
                            disabled={this.state.searching}
                            onChange={(e) => {
                                self.setState({
                                    editPurchase: update(self.state.editPurchase, { optOutOfSMS: { $set: e.target.checked } })
                                })
                            }}
                        />

                        <Form.Check
                            type="checkbox"
                            label="Out out of DM"
                            checked={self.state.editPurchase.optOutOfDM}
                            disabled={this.state.searching}
                            onChange={(e) => {
                                self.setState({
                                    editPurchase: update(self.state.editPurchase, { optOutOfDM: { $set: e.target.checked } })
                                })
                            }}
                        />

                        <Form.Check
                            type="checkbox"
                            label="VIP Subscription"
                            checked={self.state.editPurchase.subscription}
                            disabled={this.state.searching}
                            onChange={(e) => {
                                self.setState({
                                    editPurchase: update(self.state.editPurchase, { subscription: { $set: e.target.checked } })
                                })
                            }}
                        />



                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => self.setState({ editPurchase: null })} disabled={this.state.searching}>Close</Button>
                    {saveBtn}
                    {cancelOrReinstatePurchaseBtn}

                </Modal.Footer>
            </Modal>
            : null

        var savedAlert = self.state.saved ? <Alert variant="success">
            Purchase Updated
            </Alert> : null

        return (
            <Container className="mainContainer">
                {savedAlert}
                {editModal}
                {searchOptions}


                <Row>
                    <Col md={12}>{searchFields}</Col>
                </Row>
                <Row>
                    <Col md={12}>

                        <Button onClick={() => this.props.history.push("/")}>Back</Button>
                        {searchBtn}
                    </Col>
                </Row>

                {results}


            </Container>
        );


    }

}

export default withRouter(ManageSales)