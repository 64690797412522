import { connect } from 'react-redux';
import Dashboard from '../components/Dashboard';
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    apiStatus: state.service.apiStatus,
    user: state.service.user,
    events: state.service.events,

  };
}


const mapDispatchToProps = (dispatch) => ({
  logout: (callback) => {
    dispatch(api.signOut()).then(callback)
  },
  checkUser: (callback) => {
    dispatch(api.checkUserSession()).then(callback)
  },
})


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
